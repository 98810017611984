import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Main from "./components/Main.js";
import Profiles from "./components/Profiles.js";
import Footer from "./components/Footer.js";
import Results2017 from "./components/Results2017.js";
import ComingSoon from "./components/ComingSoon.js";
import About from "./components/About.js";
import Contact from "./components/Contact.js";
import Results2022 from "./components/Results2022.js";
import News from "./components/News.js";
import AthletePortal from "./components/AthletePortal.js";
// import theme from "./theme.js"

import { createTheme } from "@mui/material/styles";
import CorporateSponsors from "./components/CorporateSponsors.js";
import UpdateProfile from "./components/UpdateProfile.js";
import Results2023 from "./components/Results2023.js";
import Results2014 from "./components/Results2014.js";
import Results2013 from "./components/Results2013.js";
import Results2015 from "./components/Results2015.js";
// import { green, purple } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: "dark",
    success: { main: "#709425" },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: { borderRadius: "10px" },
      },
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar />
        <Switch>
          <Route
            path="/athlete-portal/update-profile"
            component={UpdateProfile}
          />
          <Route path="/athlete-portal" component={AthletePortal} />
          <Route path="/profiles/:sport?/:athlete?" component={Profiles} />
          <Route path="/profiles" component={Profiles} />
          <Route path="/results-2013" component={Results2013} />
          <Route path="/results-2014" component={Results2014} />
          <Route path="/results-2015" component={Results2015} />
          <Route path="/results-2017" component={Results2017} />
          <Route path="/results-2022" component={Results2022} />
          <Route path="/results-2023" component={Results2023} />
          <Route path="/about" component={About} />
          <Route path="/news" component={News} />
          <Route path="/sponsors" component={CorporateSponsors} />
          <Route path="/sponsors/individual-sponsors" component={ComingSoon} />
          <Route path="/contact" component={Contact} />
          <Route path="/">
            <Main />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
