import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Avatar,
  IconButton,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import CloseIcon from "@mui/icons-material/Close";
import SocialMediaIcons from "./SocialMediaIcons";
import useWindowDimensions from "../windowDimensions";

const ProfileModal = ({ setShowModal, sport, selectAthlete }) => {
  const history = useHistory();
  const { width } = useWindowDimensions();

  useEffect(() => {
    document.title = `Vegan Strong Plantbuilt Team: ${selectAthlete.name}`;
  }, [selectAthlete]);

  const handleCloseModal = () => {
    const lastPageArray = history.location.pathname.split("/");
    lastPageArray.pop();
    const lastPage = lastPageArray.join("/");
    history.push(lastPage);
    setShowModal(false);
  };

  const renderAthleteSocial = () => {
    if (selectAthlete.social) {
      return (
        <div style={{ display: "flex" }}>
          {selectAthlete.social.map((element, elemIdx) => (
            <SocialMediaIcons key={elemIdx} socialLink={element} />
          ))}
        </div>
      );
    }
    return "";
  };

  const renderAthleteBio = () => {
    if (selectAthlete.bio) {
      const bio = selectAthlete.bio.map((question) => {
        if (question.key === "Competition history") {
          return (
            <div key={question.key}>
              <Typography variant="body1">
                <b>{question.key}</b>:
              </Typography>
              <ul>
                {question.value.map((event, eventIdx) => (
                  <li key={eventIdx}>{event}</li>
                ))}
              </ul>
            </div>
          );
        } else {
          return (
            <Typography key={question.key} paragraph variant="body1">
              <b>{question.key}: </b>
              {question.value}
            </Typography>
          );
        }
      });
      return bio;
    }
    return "Bio coming soon!";
  };

  return (
    <Card>
      <Helmet>
        <meta
          property="og:title"
          content={`Vegan Strong Plantbuilt Team: ${selectAthlete.name}`}
        />
        <meta
          property="og:image"
          content={`https://plantbuilt.com${
            selectAthlete.meta || "/plantbuilt-default-meta.png"
          }`}
        />
      </Helmet>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#709425" }} aria-label="recipe">
            <FitnessCenterIcon />
          </Avatar>
        }
        action={
          <IconButton aria-label="settings" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        }
        title={selectAthlete.name}
        subheader={`${sport}${
          selectAthlete.name === "Giacomo Marchese" ||
          selectAthlete.name === "Dani Taylor"
            ? " - PlantBuilt Co-Founder"
            : ""
        }`}
      />
      <div
        style={{
          display: "flex",
          flexDirection: width > 900 ? "row" : "column",
        }}
      >
        <div>
          <CardMedia
            component="img"
            sx={{ width: width < 480 ? "400px" : "450px", marginLeft: "15px" }}
            image={selectAthlete.img}
            alt={selectAthlete.name}
          />
          <CardContent>{renderAthleteSocial()}</CardContent>
        </div>
        <div>
          <CardContent sx={{ paddingTop: "0px" }}>
            {renderAthleteBio()}
          </CardContent>
        </div>
      </div>
    </Card>
  );
};

export default ProfileModal;
